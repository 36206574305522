import React from "react";
import { Box, Typography } from "@mui/material";
import SignUpReleaseUpdates from "../Modals/SignUpReleaseUpdates";

interface STRMonitoringProps {
  title: string;
  subTitle: string;
  buttonText: string;
  buttonLink: string;
  backgroundSize?: any;
  minHeight?: string;
  backgroundPosition?: string;
  backgroundImage: string;
  button?: boolean;
}

const Hero: React.FC<STRMonitoringProps> = ({
  title,
  subTitle,
  buttonText,
  buttonLink,
  minHeight,
  backgroundImage,
  backgroundSize,
  backgroundPosition,
  button = true,
}) => {
  return (
    <>
      {console.log(backgroundSize)}

      <Box
        sx={{
          backgroundImage: `url(${backgroundImage})`,
          backgroundSize: backgroundSize ?? { xs: "cover", md: "120%" }, // Adjust based on screen size
          backgroundPosition: backgroundPosition ?? "top",
          backgroundRepeat: "no-repeat", // Ensure the image does not repeat
          padding: "4rem 2rem",
          minHeight: minHeight ?? "700px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          textAlign: "center",
          width: "100%",
          color: "#000",
        }}
      >
        <Typography
          variant="h4"
          sx={{
            fontWeight: 700,
            marginBottom: "1rem",
          }}
        >
          {title}
        </Typography>
        <Typography
          variant="subtitle1"
          sx={{
            marginBottom: "1rem",
          }}
        >
          {subTitle}
        </Typography>
        {/* <Link href={buttonLink} underline="none">
        <Button
          variant="outlined"
          color="primary"
          sx={{
            padding: "0.75rem 1.5rem",
            borderRadius: "2rem",
          }}
        >
          {buttonText}
        </Button>
      </Link> */}
        {button && <SignUpReleaseUpdates />}{" "}
      </Box>
    </>
  );
};

export default Hero;
