import React, { useEffect, useState } from "react";
import {
  Card,
  CardContent,
  CardMedia,
  Typography,
  Button,
  Box,
  Chip,
  CircularProgress,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import { Helmet } from "react-helmet";
import Hero from "../../Components/Hero";
import { getAllPosts } from "src/utils/apicalls";
import { Prisma } from "@prisma/client";
import { Link } from "react-router-dom";

type PostWithAuthor = Prisma.PostGetPayload<{
  include: {
    author: true; // Include the related author
  };
}>;

const BlogList: React.FC = () => {
  const [posts, setPosts] = useState<PostWithAuthor[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Fetch all posts
    getAllPosts(
      (res) => {
        setPosts(res as PostWithAuthor[]);
        setLoading(false);
      },
      (err) => console.log("Error fetching posts:", err)
    );
  }, []);

  return (
    <>
      <Helmet>
        <title>Latest Blog Posts | STR Monitoring</title>
        <meta
          name="description"
          content="Stay updated with the latest blog posts on real estate, short-term rentals, and property management tips."
        />
        <meta
          name="keywords"
          content="blog, real estate, short-term rentals, property management, short term rental monitoring, rental monitoring"
        />
        <meta
          property="og:title"
          content="Latest Blog Posts | Short-Term Rental Monitoring"
        />
        <meta
          property="og:description"
          content="Explore the latest insights and tips from our blog."
        />
        <meta property="og:image" content="/images/blog-hero.jpg" />
        <meta property="og:url" content="https://strmonitoring.com/blog" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content="Latest Blog Posts | Short-Term Rental Monitoring"
        />
        <meta
          name="twitter:description"
          content="Stay updated with the latest blog posts on real estate, short-term rentals, and property management tips."
        />
        <meta name="twitter:image" content="/images/blog-hero.jpg" />
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "Blog",
            headline: "Latest Blog Posts",
            description: "Explore the latest insights and tips from our blog.",
            url: "https://strmonitoring.com/blog",
            blogPosts: posts.map((post) => ({
              "@type": "BlogPosting",
              headline: post.title,
              description: post.description,
              image: post.coverUrl,
              url: `https://strmonitoring.com/blog/${post.slug}`,
              datePublished: post.createdAt,
              author: {
                "@type": "Person",
                name: post.author?.name,
              },
            })),
          })}
        </script>
      </Helmet>
      <Hero
        title={"Latest Blog Posts"}
        subTitle=""
        minHeight="600px"
        buttonText="Sign Up for Notifications"
        backgroundSize={{ xs: "cover", md: "cover" }}
        buttonLink="/sign-up" // Replace with the actual sign-up URL
        backgroundImage="/images/blog-hero.jpg"
        button={false}
      />
      {loading ? (
        <Box sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
          <CircularProgress />
        </Box>
      ) : (
        <Box
          sx={{
            flexGrow: 1,
            p: 2,
            backgroundColor: "#f9f9f9",
            maxWidth: "100%",
            mx: "auto", // Centers the entire grid horizontally
          }}
          component="section"
          aria-labelledby="blog-list"
        >
          <Grid
            container
            spacing={3}
            mt={2}
            justifyContent="start" // Ensures grid items are centered
            alignItems="stretch" // ← Stretches grid items to match row height
          >
            {posts.map((post) => (
              <Grid
                size={{ xs: 12, sm: 6, md: 3 }}
                sx={{
                  display: "flex", // Ensures cards are centered within the grid
                  justifyContent: "center",
                }}
                mb={2}
                key={post.id}
              >
                <Card
                  sx={{
                    maxWidth: 350,
                    width: "100%", // Ensures consistent width on all screens
                    borderRadius: 2,
                    display: "flex",
                    flexDirection: "column",
                    height: "100%",
                    boxShadow: 3,
                  }}
                >
                  <CardMedia
                    component="img"
                    height="140"
                    image={post.coverUrl}
                    alt={post.title}
                    loading="lazy"
                    sx={{ borderRadius: "4px 4px 0 0" }}
                  />
                  <CardContent sx={{ flexGrow: 1 }}>
                    {/* Title */}
                    <Typography
                      gutterBottom
                      variant="h6"
                      component="h3"
                      sx={{ fontWeight: "bold" }}
                    >
                      {post.title}
                    </Typography>

                    {/* Description */}
                    <Typography variant="body2" color="text.secondary">
                      {post.description}
                    </Typography>

                    {/* Post Date */}
                    <Typography variant="caption" color="text.secondary">
                      {new Date(post.createdAt).toLocaleDateString()}
                    </Typography>
                    {/* Tags */}
                    <Box
                      sx={{
                        mt: 2,
                        display: "flex",
                        gap: 1,
                        flexWrap: "wrap",
                        justifyContent: "center",
                      }}
                    >
                      {post.tags?.map((tag) => (
                        <Chip
                          key={tag}
                          label={tag}
                          size="small"
                          variant="outlined"
                        />
                      ))}
                    </Box>
                  </CardContent>
                  <Box
                    sx={{
                      p: 2,
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <Link
                      to={`/blog/${post.slug}`}
                      style={{ textDecoration: "none", width: "100%" }}
                    >
                      <Button
                        fullWidth
                        size="small"
                        variant="contained"
                        color="primary"
                      >
                        Read More
                      </Button>
                    </Link>
                  </Box>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Box>
      )}
    </>
  );
};

export default BlogList;
