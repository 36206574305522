import React from "react";
import { Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";

interface ShortTermRentalMonitoringProps {
  title: string;
  introText: string;
  identificationAndComplianceText: string;
  firstYearExampleText: string;
  adaptabilityText: string;
  enforcementText: string;
  problemTitle: string;
  problemDescription: string;
  solutionTitle: string;
  solutionDescription: string;
}

interface InformationSectionProps extends ShortTermRentalMonitoringProps {
  textPosition?: "left" | "right";
  imageSrc?: string;
  imageAlt?: string;
}

const InformationSection: React.FC<InformationSectionProps> = ({
  title,
  introText,
  identificationAndComplianceText,
  firstYearExampleText,
  adaptabilityText,
  enforcementText,
  problemTitle,
  problemDescription,
  solutionTitle,
  solutionDescription,
  textPosition = "left",
  imageSrc,
  imageAlt = "Information Section Image",
}) => {
  return (
    <Grid
      container
      spacing={2}
      sx={{
        padding: "4rem 2rem",
      }}
    >
      {/* TEXT SIDE */}
      <Grid
        size={{ xs: 12, md: 5 }}
        sx={{
          order: textPosition === "left" ? 1 : 2,
          marginLeft: { lg: "8rem", md: "4rem", sm: "2rem" },
        }}
      >
        {/* Main Section */}
        <div style={{ marginBottom: "2rem" }}>
          <Typography variant="h4" component="h2" gutterBottom>
            {title}
          </Typography>
          <Typography variant="body1" paragraph>
            {introText}
          </Typography>
          <Typography variant="body1" paragraph>
            {identificationAndComplianceText}
          </Typography>
          <Typography variant="body1" paragraph>
            {firstYearExampleText}
          </Typography>
          <Typography variant="body1" paragraph>
            {adaptabilityText}
          </Typography>
          <Typography variant="body1" paragraph>
            {enforcementText}
          </Typography>
        </div>

        {/* Problem Section */}
        <div style={{ marginBottom: "2rem" }}>
          <Typography variant="h5" component="h3" gutterBottom>
            {problemTitle}
          </Typography>
          <Typography variant="body1">{problemDescription}</Typography>
        </div>

        {/* Solution Section */}
        <div>
          <Typography variant="h5" component="h3" gutterBottom>
            {solutionTitle}
          </Typography>
          <Typography variant="body1">{solutionDescription}</Typography>
        </div>
      </Grid>

      {/* IMAGE SIDE */}
      <Grid
        size={{ xs: 12, md: 5 }}
        sx={{
          order: textPosition === "left" ? 2 : 1,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          minHeight: 300,
          backgroundColor: "white",
        }}
      >
        {imageSrc ? (
          <img
            src={imageSrc}
            alt={imageAlt}
            style={{ maxWidth: "100%", maxHeight: "100%" }}
          />
        ) : (
          <Typography variant="body2" color="text.secondary">
            No Image Provided
          </Typography>
        )}
      </Grid>
    </Grid>
  );
};

export default InformationSection;
