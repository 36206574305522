import React from "react";
import { Box, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";

/**
 * Each card will have:
 *  - icon: a ReactNode for a MUI icon or any JSX
 *  - title: the card's heading
 *  - description: the card's body text
 */
interface CardData {
  icon: React.ReactNode;
  title: string;
  description: string;
}

interface InformationalCardsSectionProps {
  /** Main heading at the top */
  heading: string;
  /** Subheading text beneath the main heading */
  subheading?: string;
  /** Optional bullet items under the subheading */
  bulletItems?: string[];
  /** Image URL (e.g., a screenshot) */
  imageSrc?: string;
  /** Alt text for the image */
  imageAlt?: string;
  /** Position of the image relative to the cards (default: "left") */
  imagePosition?: "left" | "right";
  /** Array of cards to display on the opposite side of the image */
  cards: CardData[];
}

const InformationalCardsSection: React.FC<InformationalCardsSectionProps> = ({
  heading,
  subheading,
  bulletItems,
  imageSrc,
  imageAlt = "Informational image",
  imagePosition = "left",
  cards,
}) => {
  return (
    <Box
      sx={{
        marginLeft: { lg: "8rem", md: "4rem", sm: "2rem" },

        padding: "4rem 2rem",
      }}
    >
      {/* Top Heading & Subheading */}
      <Box textAlign="center" mb={2}>
        <Typography variant="h4" sx={{ fontWeight: 600, color: "#0066cc" }}>
          {heading}
        </Typography>
      </Box>

      {subheading && (
        <Box textAlign="center" maxWidth="800px" mx="auto" mb={2}>
          <Typography variant="body1">{subheading}</Typography>
        </Box>
      )}

      {/* Bullet Items (Optional) */}
      {bulletItems && bulletItems.length > 0 && (
        <Box textAlign="center" mb={4}>
          {/* Render bullets in a single line with " • " or however you'd like */}
          <Typography variant="body2" sx={{ color: "text.secondary" }}>
            {bulletItems.join(" • ")}
          </Typography>
        </Box>
      )}

      {/* Main Content: Image + Cards */}
      <Grid
        container
        spacing={4}
        alignItems="center"
        direction={imagePosition === "left" ? "row" : "row-reverse"}
      >
        {/* Image Column */}
        <Grid size={{ xs: 12, md: 5 }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              minHeight: 300,
              backgroundColor: imageSrc ? "transparent" : "grey.200",
              borderRadius: 2,
              overflow: "hidden",
            }}
          >
            {imageSrc ? (
              <Box
                component="img"
                src={imageSrc}
                alt={imageAlt}
                sx={{
                  maxWidth: "100%",
                  height: "auto",
                }}
              />
            ) : (
              <Typography variant="body2" color="text.secondary">
                No Image Provided
              </Typography>
            )}
          </Box>
        </Grid>

        {/* Cards Column */}
        <Grid size={{ xs: 12, md: 5 }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 3,
            }}
          >
            {cards.map((card, index) => (
              <Box
                key={index}
                sx={{
                  backgroundColor: "#fff",
                  borderRadius: 2,
                  boxShadow: 4,
                  p: 3,
                }}
              >
                {/* Icon */}
                <Box mb={2}>{card.icon}</Box>
                {/* Title */}
                <Typography
                  variant="h6"
                  sx={{ fontWeight: 600, marginBottom: "0.5rem" }}
                >
                  {card.title}
                </Typography>
                {/* Description */}
                <Typography variant="body2" sx={{ color: "text.secondary" }}>
                  {card.description}
                </Typography>
              </Box>
            ))}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default InformationalCardsSection;
