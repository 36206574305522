import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Box, Typography, Chip, CircularProgress } from "@mui/material";
import { Prisma } from "@prisma/client";
import { getPost } from "src/utils/apicalls";
import { Helmet } from "react-helmet";

type PostWithAuthor = Prisma.PostGetPayload<{
  include: {
    author: true; // Include the related author
  };
}>;

const BlogPost = () => {
  const { slug } = useParams<{ slug: string }>();
  const [post, setPost] = useState<PostWithAuthor | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const script1 = document.createElement("script");
    script1.src = "https://news.google.com/swg/js/v1/swg-basic.js";
    script1.async = true;

    const script2 = document.createElement("script");
    script2.type = "application/javascript";
    script2.innerHTML = `
      (self.SWG_BASIC = self.SWG_BASIC || []).push( basicSubscriptions => {
        basicSubscriptions.init({
          type: "NewsArticle",
          isPartOfType: ["Product"],
          isPartOfProductId: "CAowpeTZCw:openaccess",
          clientOptions: { theme: "light", lang: "en" },
        });
      });
    `;

    document.body.appendChild(script1);
    document.body.appendChild(script2);

    return () => {
      document.body.removeChild(script1);
      document.body.removeChild(script2);
    };
  }, []);

  useEffect(() => {
    if (slug) {
      getPost(
        slug,
        (response) => {
          setPost(response); // Set the post data
          setLoading(false);
        },
        (error) => {
          console.error("Error fetching post:", error);
          setLoading(false);
        }
      );
    }
  }, [slug]);

  if (loading) {
    return (
      <Box sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
        <CircularProgress />
      </Box>
    );
  }

  if (!post) {
    return (
      <Box sx={{ textAlign: "center", mt: 4 }}>
        <Typography variant="h5">Post not found</Typography>
      </Box>
    );
  }

  return (
    <>
      {/* Dynamic SEO Meta Tags */}
      <Helmet>
        <title>{post.title} | Short-Term Rental Monitoring</title>
        <meta name="description" content={post.description} />
        <meta name="keywords" content={post.tags?.join(", ")} />
        <meta property="og:title" content={post.title} />
        <meta property="og:description" content={post.description} />
        <meta property="og:image" content={post.coverUrl} />
        <meta
          property="og:url"
          content={`https://strmonitoring.com/blog/${post.slug}`}
        />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={post.title} />
        <meta name="twitter:description" content={post.description} />
        <meta name="twitter:image" content={post.coverUrl} />
        <link
          rel="canonical"
          href={`https://strmonitoring.com/blog/${post.slug}`}
        />
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "BlogPosting",
            headline: post.title,
            image: post.coverUrl,
            author: {
              "@type": "Person",
              name: post.author.name,
              url: "https://strmonitoring.com",
            },
            publisher: {
              "@type": "Organization",
              name: "STR Monitoring",
              logo: {
                "@type": "ImageObject",
                url: "/images/logo.png", // Replace with your website logo
              },
            },
            datePublished: post.createdAt,
            dateModified: post.createdAt,
            description: post.description,
            mainEntityOfPage: {
              "@type": "WebPage",
              "@id": `https://strmonitoring.com/blog/${post.slug}`,
            },
          })}
        </script>
      </Helmet>

      {/* Page Content */}
      <Box sx={{ maxWidth: "1200px", mx: "auto", my: 4, p: 2 }}>
        {/* Title */}
        <Typography variant="h3" sx={{ fontWeight: "bold", mb: 2 }}>
          {post.title || "Untitled"}
        </Typography>

        {/* Date and Duration */}
        <Typography variant="subtitle2" sx={{ fontWeight: "bold" }}>
          {post.author.name || "Unknown Author"}
        </Typography>
        <Typography variant="subtitle2" sx={{ color: "gray", mb: 4 }}>
          {post.createdAt
            ? new Date(post.createdAt).toLocaleDateString()
            : "Date unknown"}
          {post.duration && ` • ${post.duration}`}
        </Typography>

        {/* Author Information */}
        {
          // post.author && (
          //   <Box sx={{ mt: 4, display: "flex", alignItems: "center", gap: 2 }}>
          //     {/* Avatar */}
          //     {post.author.avatarUrl && (
          //       <img
          //         src={post.author.avatarUrl}
          //         alt={post.author.name || "Author"}
          //         style={{
          //           width: "80px",
          //           height: "80px",
          //           borderRadius: "50%",
          //         }}
          //       />
          //     )}
          //     {/* Author Details */}
          //     <Box>
          //       <Typography variant="h6" sx={{ fontWeight: "bold" }}>
          //         {post.author.name || "Unknown Author"}
          //       </Typography>
          //       <Typography variant="subtitle2" sx={{ color: "gray" }}>
          //         {post.author.role || "Role unknown"}
          //       </Typography>
          //     </Box>
          //   </Box>
          // )
        }

        {/* Cover Image */}
        {post.coverUrl && (
          <Box sx={{ mb: 4, mt: 4, display: "flex", justifyContent: "center" }}>
            <img
              src={post.coverUrl}
              alt={post.title || "Blog post cover"}
              style={{
                width: "100%",
                maxHeight: "700px",
                maxWidth: "700px",
                borderRadius: "8px",
              }}
            />
          </Box>
        )}

        {/* Tags */}
        <Box sx={{ mb: 4 }}>
          {post.tags?.length ? (
            post.tags.map((tag, index) => (
              <Chip key={index} label={tag} sx={{ mr: 1, mb: 1 }} />
            ))
          ) : (
            <Typography variant="body2" color="text.secondary">
              No tags available
            </Typography>
          )}
        </Box>

        {/* Post Content */}
        <Box sx={{ mb: 4, textAlign: "start" }}>
          {post.post ? (
            <Typography
              variant="body1"
              dangerouslySetInnerHTML={{ __html: post.post }}
            />
          ) : (
            <Typography variant="body2" color="text.secondary">
              No content available for this post.
            </Typography>
          )}
        </Box>
      </Box>
    </>
  );
};

export default BlogPost;
