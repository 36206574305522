import React from "react";
import { Container, Typography, Box, Divider } from "@mui/material";
import Helmet from "react-helmet";

const TermsAndPrivacy = () => {
  return (
    <>
      <Helmet>
        <title>Terms of Service and Privacy Policy | STR Monitoring</title>
        <meta
          name="description"
          content="Review the Terms of Service and Privacy Policy for STR Monitoring, your trusted short-term rental monitoring solution."
        />
        <meta
          name="keywords"
          content="terms of service, privacy policy, short term rental monitoring, rental monitoring, property management"
        />
        <meta
          property="og:title"
          content="Terms of Service and Privacy Policy | Short-Term Rental Monitoring"
        />
        <meta
          property="og:description"
          content="Learn about the terms and conditions and how we protect your privacy at STR Monitoring."
        />
        <meta property="og:image" content="/images/terms-privacy-hero.jpg" />
        <meta
          property="og:url"
          content="https://strmonitoring.com/terms-privacy"
        />
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content="Terms of Service and Privacy Policy | Short-Term Rental Monitoring"
        />
        <meta
          name="twitter:description"
          content="Review the Terms of Service and Privacy Policy for STR Monitoring, your trusted short-term rental monitoring solution."
        />
        <meta name="twitter:image" content="/images/terms-privacy-hero.jpg" />
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "WebPage",
            headline: "Terms of Service and Privacy Policy",
            description:
              "Review the Terms of Service and Privacy Policy for STR Monitoring, your trusted short-term rental monitoring solution.",
            url: "https://strmonitoring.com/terms-privacy",
          })}
        </script>
      </Helmet>

      <Container
        sx={{
          textAlign: "start",
        }}
        maxWidth={"lg"}
      >
        {/* Terms of Service Section */}
        <Box my={4}>
          <Typography variant="h4" gutterBottom>
            Terms of Service
          </Typography>
          <Typography variant="body1" paragraph>
            Welcome to strmonitoring.com. By accessing or using our website, you
            agree to be bound by these terms and conditions. Please read them
            carefully.
          </Typography>
          <Typography variant="h6" gutterBottom>
            1. Use of Service
          </Typography>
          <Typography variant="body1" paragraph>
            You agree to use our website only for lawful purposes and in
            accordance with these terms. Unauthorized use of this website may
            result in a claim for damages or be a criminal offense.
          </Typography>
          <Typography variant="h6" gutterBottom>
            2. Intellectual Property
          </Typography>
          <Typography variant="body1" paragraph>
            All content on this website, including text, graphics, logos, and
            software, is the property of strmonitoring.com or its content
            suppliers and is protected by copyright and other intellectual
            property laws.
          </Typography>
          <Typography variant="h6" gutterBottom>
            3. Limitation of Liability
          </Typography>
          <Typography variant="body1" paragraph>
            strmonitoring.com will not be liable for any damages arising out of
            your use or inability to use the website.
          </Typography>
        </Box>

        <Divider sx={{ my: 4 }} />

        {/* Privacy Policy Section */}
        <Box my={4}>
          <Typography variant="h4" gutterBottom>
            Privacy Policy
          </Typography>
          <Typography variant="body1" paragraph>
            Effective Date: January 1, 2024
          </Typography>
          <Typography variant="body1" paragraph>
            Your privacy is important to us. This policy outlines how we
            collect, use, disclose, and protect your personal information.
          </Typography>
          <Typography variant="h6" gutterBottom>
            1. Information We Collect
          </Typography>
          <Typography variant="body1" paragraph>
            We may collect personal information such as your name, email
            address, IP address, and any other information you provide when
            using our website or services.
          </Typography>
          <Typography variant="h6" gutterBottom>
            2. How We Use Your Information
          </Typography>
          <Typography variant="body1" paragraph>
            We use your information to provide services, respond to inquiries,
            improve our website, and for legal compliance. We do not sell your
            personal information to third parties.
          </Typography>
          <Typography variant="h6" gutterBottom>
            3. Disclosure of Information
          </Typography>
          <Typography variant="body1" paragraph>
            We may share your information with trusted service providers to help
            us deliver our services. We may also disclose your information to
            comply with legal obligations or protect our rights.
          </Typography>
          <Typography variant="h6" gutterBottom>
            4. Data Security
          </Typography>
          <Typography variant="body1" paragraph>
            We implement appropriate security measures to protect your data.
            However, no method of transmission over the internet is completely
            secure, and we cannot guarantee absolute security.
          </Typography>
          <Typography variant="h6" gutterBottom>
            5. Your Privacy Rights
          </Typography>
          <Typography variant="body1" paragraph>
            Depending on your location, you may have the right to access,
            correct, or delete your personal data. To exercise these rights,
            contact us at customerservice@strmonitoring.com.
          </Typography>
          <Typography variant="h6" gutterBottom>
            6. Changes to This Policy
          </Typography>
          <Typography variant="body1" paragraph>
            We may update this Privacy Policy from time to time. Changes will be
            posted on this page with the updated effective date.
          </Typography>
        </Box>
      </Container>
    </>
  );
};

export default TermsAndPrivacy;
