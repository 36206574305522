import React from "react";
import { Box, Typography, Card, CardContent, CardMedia } from "@mui/material";
import Grid from "@mui/material/Grid2";

interface CardProps {
  image: string;
  title: string;
  description: string;
}

interface FeatureSectionProps {
  sectionText: string;
  headerText: string;
  subText: string;
  cards: CardProps[];
}

const FeatureSection: React.FC<FeatureSectionProps> = ({
  sectionText,
  headerText,
  subText,
  cards,
}) => {
  return (
    <Box
      sx={{
        padding: "4rem 2rem",
        backgroundColor: "#f5faff", // Updated background color
      }}
    >
      {/* Top Section */}
      <Box
        sx={{
          textAlign: "left", // Align text to the left
          marginBottom: "3rem",
          marginLeft: { lg: "8rem", md: "4rem", sm: "2rem" },
        }}
      >
        <Typography
          variant="overline"
          sx={{
            fontWeight: 600,
            color: "#0066cc",
            marginBottom: "1rem",
            display: "block",
            textTransform: "uppercase",
          }}
        >
          {sectionText}
        </Typography>

        <Typography
          variant="h4"
          sx={{
            fontWeight: 700,
            marginBottom: "1rem",
            color: "#000",
          }}
        >
          {headerText}
        </Typography>

        <Typography
          variant="body1"
          sx={{
            color: "#555",
            lineHeight: "1.8",
            maxWidth: "1200px",
          }}
        >
          {subText}
        </Typography>
      </Box>

      {/* Grid of Cards */}
      <Grid
        container
        spacing={4}
        marginTop={10}
        sx={{
          justifyContent: "center",
        }}
      >
        {cards.map((card, index) => (
          <Grid
            key={index}
            size={{ xs: 12, sm: 6, md: 3 }}
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Card
              sx={{
                width: "100%",
                maxWidth: "350px",
                boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
                borderRadius: "16px",
                overflow: "hidden",
                transition: "transform 0.2s ease-in-out",
                "&:hover": {
                  transform: "scale(1.05)",
                },
              }}
            >
              <CardMedia
                component="img"
                height="200"
                image={card.image}
                alt={card.title}
                sx={{
                  objectFit: "contain",
                }}
              />
              <CardContent
                sx={{
                  padding: "1.5rem",
                  textAlign: "left",
                }}
              >
                <Typography
                  variant="h6"
                  sx={{
                    fontWeight: 600,
                    marginBottom: "0.5rem",
                    color: "#000",
                  }}
                >
                  {card.title}
                </Typography>
                <Typography
                  variant="body2"
                  sx={{
                    color: "#555",
                    lineHeight: "1.6",
                  }}
                >
                  {card.description}
                </Typography>
              </CardContent>
              {/* <Box
                sx={{
                  padding: "1rem",
                  textAlign: "right",
                }}
              >
                <Link
                  href="#"
                  underline="none"
                  sx={{
                    color: "#0066cc",
                    fontWeight: 600,
                    fontSize: "1.2rem",
                  }}
                >
                  →
                </Link>
              </Box> */}
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default FeatureSection;
