import React, { useContext } from "react";
import Lottie from "react-lottie";
import uploadAnimation from "../animations/uploadAnimation.json";
import { Box, Typography, Button } from "@mui/material";
import { UIContext, UIState } from "src/providers/UIProvider";
import { oneTimeServiceSTRLookup } from "src/utils/apicalls";

type Props = {};

interface CSVRow {
  [key: string]: string;
}

const Index = (props: Props) => {
  const [csvData, setCsvData] = React.useState<CSVRow[]>([]);
  const [, dispatch] = useContext<UIState | any>(UIContext);

  // Configure Lottie
  const defaultLottieOptions = {
    loop: true,
    autoplay: true,
    animationData: uploadAnimation,
  };

  // Handle CSV upload

  const handleCSVUpload = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (!event.target.files || event.target.files.length === 0) return;

    const file = event.target.files[0];

    try {
      const text = await file.text();
      console.log("CSV file contents:", text);

      // Split the file content into rows (filter out empty lines)
      const rows = text.split("\n").filter((row) => row.trim() !== "");

      // Extract headers from the first row
      const headers = rows[0].split(",").map((header) => header.trim());

      // Define which fields are required
      const requiredFields = [
        "City",
        "State/Province",
        "Postal Code",
        "Street Address",
      ];

      let hasError = false;

      // Build an array of objects (one per row)
      const csvDataArray: CSVRow[] = rows.slice(1).map((row, rowIndex) => {
        const values = row.split(",").map((value) => value.trim());
        const rowData: CSVRow = {};

        headers.forEach((header, colIndex) => {
          const value = values[colIndex] || "";
          rowData[header] = value;

          // Check for missing values in required fields
          if (requiredFields.includes(header) && !value) {
            hasError = true;
            console.error(
              `Validation Error: "${header}" is missing in row ${rowIndex + 2}`
            );
          }
        });

        return rowData;
      });

      // If there are errors, dispatch the Snackbar error message
      if (hasError) {
        dispatch({
          type: "Snackbar",
          payload: {
            show: true,
            duration: 10000,
            message:
              "Error: Some required fields (City, State/Province, Postal Code, or Street Address) are missing in the uploaded CSV. Please fix and try again.",
            severity: "error",
          },
        });
        return;
      }

      // If no errors, set state and dispatch success
      console.log("Parsed CSV Data as Array of Objects:", csvDataArray);

      handleSendToBackend(csvDataArray);
      setCsvData(csvDataArray);

      dispatch({
        type: "Snackbar",
        payload: {
          show: true,
          message:
            "You’ve successfully subscribed to updates and notifications for STR Monitoring. Thank you!",
          severity: "success",
        },
      });
    } catch (err) {
      console.error("Error processing CSV file:", err);
      dispatch({
        type: "Snackbar",
        payload: {
          show: true,
          message: "An error occurred while processing the CSV file.",
          severity: "error",
        },
      });
    }
  };

  const handleSendToBackend = async (csvDataArray: CSVRow[]) => {
    oneTimeServiceSTRLookup(
      csvDataArray,
      (res: any) => console.log(res),
      (err) => console.error("Error calling geocode endpoint:", err)
    );
  };

  return (
    <Box
      // Fill the parent space provided by the layout
      sx={{
        height: "100%", // fill parent's height (the parent is flexGrow: 1 in App.tsx)
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        p: 2,
      }}
    >
      <Lottie options={defaultLottieOptions} height={400} width={400} />
      <Typography variant="h6" sx={{ mb: 2 }}>
        Upload your property CSV based on our template
      </Typography>

      <Button variant="contained" component="label">
        Select CSV
        <input type="file" accept=".csv" hidden onChange={handleCSVUpload} />
      </Button>

      {/* Example: show a snippet of the CSV if you want */}
      {csvData && (
        <Box
          mt={2}
          sx={{
            maxHeight: 200,
            overflow: "auto",
            backgroundColor: "#f0f0f0",
            p: 1,
            width: "80%",
          }}
        >
          <Typography variant="body2" component="pre" whiteSpace="pre-wrap">
            {csvData && <pre>{JSON.stringify(csvData, null, 2)}</pre>}
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default Index;
