import React from "react";
import "./App.css";
// import ComingSoon from "./Pages/ComingSoon";

import { ThemeProvider, CssBaseline, Box } from "@mui/material";
import theme from "./theme"; // Import the theme
import SnackbarAlert from "./Components/SnackbarAlert";
import Navbar from "./Components/Navbar";
import Footer from "./Components/Footer";
import Home from "./Pages/Home";
import ReactGA from "react-ga4";
import { BrowserRouter, Route, Routes } from "react-router";
import Posts from "./Pages/Posts";
import BlogPost from "./Pages/BlogPost"; // Import the new BlogPost component
import TermsAndPrivacy from "./Pages/TermsServicePolicy";
import OneTime from "./Pages/Services/OneTime";

ReactGA.initialize("G-VH88X78D14"); // Replace with your GA4 Measurement ID
ReactGA.send("pageview"); // Tracks the initial page load

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          minHeight: "100vh", // Make the entire layout take up full screen height
        }}
      >
        {" "}
        <BrowserRouter>
          <SnackbarAlert />
          <Navbar />
          <Box
            component="main"
            sx={{
              flexGrow: 1,
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/blog" element={<Posts />} />
              <Route path="/blog/:slug" element={<BlogPost />} />
              <Route path="/terms-privacy" element={<TermsAndPrivacy />} />
              <Route path="/service/one-time" element={<OneTime />} />
            </Routes>
          </Box>
          {/* <ComingSoon /> */}
          <Footer />
        </BrowserRouter>
      </Box>
    </ThemeProvider>
  );
}

export default App;
